import React from "react";
import { Link } from 'react-router-dom';

import styles from "./Topbar.module.scss";
import { FormattedMessage } from "react-intl";
import LogoHeader from "./LogoHeader";

function Topbar() {

    return (
        <div className={styles.main}>
          <div className={styles.wrap}>
            <div className={styles.logoContainer}>
              <LogoHeader
                SetRectangle={styles.SetRectangle}
                clipPath1={styles.clipPath1}
                setFill1={styles.setFill1}
                clipPath2={styles.clipPath2}
                setFill2={styles.setFill2}
              />
            </div>
          </div>
        </div>
    );
}

export default Topbar;
