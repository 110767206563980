import React from "react";

function LogoFooter(props) {

return (
<svg xmlns="http://www.w3.org/2000/svg" width="1046" height="240" viewBox="0 0 1046 240">
  <defs>
    <filter id="logoFooter_rect_1" x="-22.5" y="-17.5" width="1091" height="285" filterUnits="userSpaceOnUse">
      <feOffset dy="5" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="7.5" result="blur"/>
      <feFlood flood-opacity="0.459"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="logo_footer" transform="translate(84 -5316)">
    <g id="Enmascarar_grupo_2" data-name="Enmascarar grupo 2" className={props.setClipPath} transform="translate(-67.5 5339.648)">
      <g id="Simbolo" transform="translate(-17 -414.754)">
        <path id="Trazado_181" data-name="Trazado 181" className={props.setColor} d="M726.313,450.932A67.382,67.382,0,1,0,793.7,518.313,67.381,67.381,0,0,0,726.313,450.932Zm0,101.968A34.586,34.586,0,1,1,760.9,518.313,34.586,34.586,0,0,1,726.313,552.9Z" transform="translate(-213.568 -5.568)"/>
        <path id="Trazado_182" data-name="Trazado 182" className={props.setColor} d="M976.245,596.309a91.115,91.115,0,0,1-90.264-103.828l-25.008-31.259L463.5,505.154l423.869,45.577L698.223,835.59,932.947,614.54l43.3,403.359L1020.3,613.033l-22.982-19.186A91.413,91.413,0,0,1,976.245,596.309Z" transform="translate(-463.5 7.591)"/>
        <path id="Trazado_183" data-name="Trazado 183" className={props.setColor} d="M711.711,677.09a91.1,91.1,0,0,1,89.961,105.8l23.559,29.73,401.5-44.376L798.8,717.353,992.012,437.809,757.289,658.859,713.99,255.5,670.35,656.576l26.934,21.677A91.366,91.366,0,0,1,711.711,677.09Z" transform="translate(-198.966 -255.5)"/>
        <path id="Trazado_184" data-name="Trazado 184" className={props.setColor} d="M982.073,704.832A86.544,86.544,0,0,0,881.272,603.8L525.5,317.5l286,357.493a86.622,86.622,0,0,0,107.672,97.672l344.685,287.746Zm-85.118,59.658a75.535,75.535,0,1,1,75.535-75.535A75.535,75.535,0,0,1,896.955,764.491Z" transform="translate(-384.21 -176.21)"/>
      </g>
    </g>
    <g id="Logotipo" transform="translate(-147.5 4773.25)">
      <path id="Trazado_164" data-name="Trazado 164" className={props.setLogo} d="M723.546,636.812v30.836h-.2l-17.14-20.2v19.713H702v-30.8h.216l17.107,20.164V636.812Z" transform="translate(-274.5 11.481)"/>
      <path id="Trazado_165" data-name="Trazado 165" className={props.setLogo} d="M770.745,642.082v7.747h10.141v4.727H770.745v8.366H782.5v4.727h-15.96V637.411H782.5v4.67Z" transform="translate(-306.721 10.957)"/>
      <path id="Trazado_166" data-name="Trazado 166" className={props.setLogo} d="M817.864,656.187V637.411h4.223v18.757c0,4.183,2.61,7.24,5.885,7.24s5.919-3.058,5.919-7.24V637.411h4.239v18.775c0,6.94-4.439,12.023-10.142,12.023S817.864,663.127,817.864,656.187Z" transform="translate(-332.346 10.957)"/>
      <path id="Trazado_167" data-name="Trazado 167" className={props.setLogo} d="M894.046,667.647l-7.215-11.7h-3.291v11.7h-4.206l-.017-30.236h8.329c4.987,0,9.078,4.332,9.078,9.716,0,3.9-2.228,7.3-5.3,8.422l7.8,12.1ZM887.745,652a4.869,4.869,0,0,0,4.638-4.989,4.746,4.746,0,0,0-4.638-4.9h-4.223V652Z" transform="translate(-363.026 10.957)"/>
      <path id="Trazado_168" data-name="Trazado 168" className={props.setLogo} d="M933.363,652.043c0-8.628,6.218-15.681,13.849-15.681,7.614,0,13.866,7.052,13.866,15.681,0,8.553-6.251,15.568-13.882,15.568C939.581,667.611,933.363,660.6,933.363,652.043Zm23.458-.038c0-5.983-4.339-10.86-9.626-10.86s-9.593,4.877-9.593,10.86c0,5.946,4.306,10.8,9.593,10.8S956.821,657.951,956.821,652.005Z" transform="translate(-390.009 11.481)"/>
      <path id="Trazado_169" data-name="Trazado 169" className={props.setLogo} d="M1029.176,636.812v30.836h-.2l-17.14-20.2v19.713h-4.206v-30.8h.216l17.107,20.164V636.812Z" transform="translate(-427.087 11.481)"/>
      <path id="Trazado_170" data-name="Trazado 170" className={props.setLogo} d="M1069.613,652.043c0-8.628,6.218-15.681,13.849-15.681,7.614,0,13.865,7.052,13.865,15.681,0,8.553-6.251,15.568-13.882,15.568C1075.831,667.611,1069.613,660.6,1069.613,652.043Zm23.458-.038c0-5.983-4.339-10.86-9.626-10.86s-9.592,4.877-9.592,10.86c0,5.946,4.306,10.8,9.592,10.8S1093.071,657.951,1093.071,652.005Z" transform="translate(-458.032 11.481)"/>
      <path id="Trazado_171" data-name="Trazado 171" className={props.setLogo} d="M1156.225,642.119h-7.182v25.528h-4.223V642.119h-7.182v-4.708h18.587Z" transform="translate(-491.994 10.957)"/>
      <path id="Trazado_172" data-name="Trazado 172" className={props.setLogo} d="M1196.854,642.082v7.747H1207v4.727h-10.141v8.366h11.754v4.727h-15.96V637.411h15.96v4.67Z" transform="translate(-519.458 10.957)"/>
      <path id="Trazado_173" data-name="Trazado 173" className={props.setLogo} d="M1241.384,661.8l3.325-2.683a6.582,6.582,0,0,0,6,3.714c3.009,0,5.287-1.632,5.287-3.939,0-2.8-3.524-4.483-6.65-6-3.142-1.576-6.634-3.414-6.634-8.159a8.293,8.293,0,0,1,8.629-8.4,7.783,7.783,0,0,1,7.781,5.4L1256,644.165a4.969,4.969,0,0,0-4.622-3.189,4.157,4.157,0,0,0-4.422,3.864c0,2.363,2.477,3.208,5.736,4.783,4.239,2.1,7.531,4.408,7.531,9.172,0,5.364-4.123,8.816-9.559,8.816A9.741,9.741,0,0,1,1241.384,661.8Z" transform="translate(-543.79 11.5)"/>
      <path id="Trazado_174" data-name="Trazado 174" className={props.setLogo} d="M1298.713,673.875V650h2.508v20.926h4.57v2.949Z" transform="translate(-572.411 4.672)"/>
      <path id="Trazado_175" data-name="Trazado 175" className={props.setLogo} d="M1330.916,673.875l-.724-5.609h-3.4l-.669,5.609H1323.7L1326.848,650h3.371l3.149,23.875Zm-3.79-8.378h2.731l-1.31-10.463h-.111Z" transform="translate(-584.886 4.672)"/>
      <path id="Trazado_176" data-name="Trazado 176" className={props.setLogo} d="M1354.415,673.875V650h5.044a2.959,2.959,0,0,1,2.717,1.258,6.594,6.594,0,0,1,.794,3.524v2.445q0,3.344-1.867,3.919v.144a2.425,2.425,0,0,1,1.588,1.133,5.7,5.7,0,0,1,.529,2.822v4.531a5.105,5.105,0,0,1-.767,3.074,3.082,3.082,0,0,1-2.633,1.025Zm6.214-18.625a3.8,3.8,0,0,0-.349-1.834,1.36,1.36,0,0,0-1.3-.611h-2.118V660h2.146a1.444,1.444,0,0,0,1.17-.539,3.059,3.059,0,0,0,.446-1.906Zm.112,9.78a3.065,3.065,0,0,0-.4-1.8,1.58,1.58,0,0,0-1.351-.539h-2.118v8.378h2.2a1.435,1.435,0,0,0,1.282-.575,3.1,3.1,0,0,0,.391-1.726Z" transform="translate(-600.221 4.672)"/>
    </g>
  </g>
</svg>
);
}

export default LogoFooter;
