import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';

import styles from "./Footer.module.scss";
import { FormattedMessage } from "react-intl";
import useOutsideClick from "../outsideClick/useOutsideClick";
import LogoFooter from "./logo_footer";

function Footer({ onChangeLanguage }) {

  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
  const [language, setLanguage] = useState("English");
  const appLang = window.localStorage.getItem("language");
  const ref = useRef();

  useOutsideClick(ref, () => {
    setShowLanguageSelector(false);
  });

  useEffect(() => {
    setLanguage(checkLang(appLang));
  }, [])

  const checkLang = (e) => {
    switch (e) {
      case "en":
        return "English"
        break;
      case "es":
        return "Spanish"
        break;
      case "de":
        return "German"
        break;
      default:
        return "English"
    }
  }
  const chooseLanguage = (e) => {
    onChangeLanguage(e);
    setShowLanguageSelector(false);
  }

  return (
    <div className={styles.main}>
      <div className={styles.wrap}>
        <div className={styles.bgRight}>
        </div>
        <div className={styles.bgLogo}>
          <LogoFooter
            setClipPath={styles.logoSetClipPath}
            setColor={styles.logoBgColor}
            setLogo={styles.logoTextColor}
            />
        </div>
        <div className={styles.navWrap}>
          <div className={styles.column}>
            <a href="https://www.patchnotes.com/impressum" className={styles.squarelessBtn} rel="noopener noreferrer" target="_blank">
              <span><FormattedMessage id="impressum" /></span>
            </a>
            <Link to={"/project"} className={styles.disable}>
              <span><FormattedMessage id="project" /></span>
            </Link>
          </div>
          <div className={styles.column}>
            <a href="https://www.patchnotes.com/datenschutz" className={styles.squarelessBtn} rel="noopener noreferrer" target="_blank">
              <span><FormattedMessage id="datenschutz" /></span>
            </a>
            <Link to={"/contac"} className={styles.disable}>
              <span><FormattedMessage id="contact" /></span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
