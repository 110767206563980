import React from "react";
import { Route, HashRouter } from 'react-router-dom';

import styles from "./Landingscreen.module.scss";
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import NeuroNotes from "../neuroNotes/NeuroNotes";

function Landingscreen({ onChangeLanguage }) {
    return (
        <div className={styles.main} id={"Landingscreen"}>
          <HashRouter>
            <div className={styles.topbar}>
                <Topbar/>
              </div>
            <div className={styles.router}>
                <Route exact path="/">
                  <NeuroNotes/>
                </Route>

            </div>
            <div className={styles.footer}>
                <Footer onChangeLanguage={(e) => onChangeLanguage(e)}/>
            </div>
          </HashRouter>
        </div>
    );
}

export default Landingscreen;
