import React from "react";

import styles from "./NeuroNotes.module.scss";
import { FormattedMessage } from "react-intl";

import logoImg from "../../images/neuroNotes/symbol_Neuronotes.png"

function NeuroNotes() {

    return (
        <div className={styles.main}>
            <div className={styles.wrap}>
                <div className={styles.landingScreen}>
                    <div className={styles.topBorderEffect}/>
                    <div className={styles.container}>
                        <img src={logoImg} className={styles.logo} alt=""/>
                        <div className={styles.logoText}/>
                        <div className={styles.textWrap}>
                            <span className={styles.title}><FormattedMessage id="neuroNotes_title"/></span>
                            <span className={styles.subTitle}><FormattedMessage id="neuroNotes_subTitle"/></span>
                        </div>  
                        <div className={styles.comingSoonWrap}>
                            <span><FormattedMessage id="neuroNotes_commingSoon"/></span>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NeuroNotes;
