let messagesEN

export default messagesEN = {

    // NEURONOTES

    neuroNotes_title: "Neuroscience and Education research laboratory",
    neuroNotes_subTitle: "Project Director Beatriz de Zulliger",
    neuroNotes_commingSoon: "More coming soon early 2022.",

    // FOOTER
    contact: "Contact",
    datenschutz: "Datenschutz",
    about: "About",
    project: "Project",
    impressum: "Impressum",

}